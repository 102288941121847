<script lang="ts">
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'HeroComponent',
  setup() {
    const store = useStore()
    const { hero } = storeToRefs(store)
    return { hero }
  }
})
</script>

<template>
  <div>
    <a v-if="hero.link_url !== ''" :href="hero.link_url" :aria-label="hero.link_text" class="hero">
      <picture v-if="hero.size_1x">
        <source
          v-if="hero.size_2x.mobile"
          media="(max-width: 640px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.mobile"
        />
        <source
          v-if="hero.size_2x.medium"
          media="(max-width: 1200px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.medium"
        />
        <source
          v-if="hero.size_2x.large"
          media="(min-width: 1201px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.large"
        />
        <source
          v-if="hero.size_1x.mobile"
          media="(max-width: 640px)"
          :srcset="hero.size_1x.mobile"
        />
        <source
          v-if="hero.size_1x.medium"
          media="(max-width: 1200px)"
          :srcset="hero.size_1x.medium"
        />
        <source
          v-if="hero.size_1x.large"
          media="(min-width: 1201px)"
          :srcset="hero.size_1x.large"
        />
        <img :src="hero.size_1x.mobile" width="640" height="640" :alt="hero.alt" />
      </picture>
    </a>
    <div v-else class="hero">
      <picture v-if="hero.size_1x">
        <source
          v-if="hero.size_2x.mobile"
          media="(max-width: 640px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.mobile"
        />
        <source
          v-if="hero.size_2x.medium"
          media="(max-width: 1200px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.medium"
        />
        <source
          v-if="hero.size_2x.large"
          media="(min-width: 1201px) and (-webkit-device-pixel-ratio:2)"
          :srcset="hero.size_2x.large"
        />
        <source
          v-if="hero.size_1x.mobile"
          media="(max-width: 640px)"
          :srcset="hero.size_1x.mobile"
        />
        <source
          v-if="hero.size_1x.medium"
          media="(max-width: 1200px)"
          :srcset="hero.size_1x.medium"
        />
        <source
          v-if="hero.size_1x.large"
          media="(min-width: 1201px)"
          :srcset="hero.size_1x.large"
        />
        <img :src="hero.size_1x.mobile" width="640" height="640" :alt="hero.alt" />
      </picture>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 550px;
  @media screen and (max-width: 1200px) {
    height: 400px;
  }
  @media screen and (max-width: 640px) {
    height: 265px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: block;
    object-fit: cover;
    height: 100%;
  }
}
</style>
