<script lang="ts">
import { defineComponent } from 'vue'
import ProductsQuery from './ProductsQuery.vue'
import CampaignCard from './CampaignCard.vue'
import { storeToRefs } from 'pinia'
import { useStore } from '../store'

export default defineComponent({
  name: 'GridFrontPage',
  components: {
    ProductsQuery,
    CampaignCard
  },
  setup() {
    const store = useStore()
    const { campaigns } = storeToRefs(store)
    return { campaigns }
  },
  computed: {
    getCampaigns() {
      if (!this.campaigns) {
        return null
      }
      return this.campaigns.hits
    }
  }
})
</script>

<template>
  <div>
    <ProductsQuery
      query-id="frontpage"
      :show-filter="false"
      :query="{
        limit: 14,
        offset: 0,
        filters: [
          {
            key: 'product_visibility',
            type: 'taxonomy',
            operator: 'in',
            value: ['featured']
          }
        ]
      }"
    />
    <!-- Temporary grid to just display the campaign boxes these should be added into the product grid later -->
    <div v-if="getCampaigns" class="campaign-grid">
      <CampaignCard v-for="campaign in getCampaigns" v-bind="campaign" :key="campaign.id" />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';

.campaign-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1300px) {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
