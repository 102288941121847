<script lang="ts">
import { defineComponent } from 'vue'
import apiClient from '../api/api.js'
import GridFrontPage from '../components/GridFrontPage.vue'
import Hero from '../components/Hero.vue'
import { useStore } from '../store'
import { isClient } from '../utils/helpers'

export default defineComponent({
  name: 'FrontPage',
  components: {
    Hero,
    GridFrontPage
  },
  beforeRouteEnter(to, from, next) {
    if (isClient && useStore()?.campaigns && useStore()?.hero) {
      next()
      return
    }
    Promise.all([apiClient.fetchHero(), apiClient.fetchCampaigns()]).then(
      resps => {
        next((vm: any) => {
          vm.setHero(resps[0])
          vm.setCampaigns(resps[1])
        })
      }
    )
  },
  beforeRouteUpdate(to, from, next) {
    Promise.all([apiClient.fetchHero(), apiClient.fetchCampaigns()]).then(
      resps => {
        this.setHero(resps[0])
        this.setCampaigns(resps[1])
        next()
      }
    )
  },
  setup() {
    const { setHero, setCampaigns, fetchHero, fetchCampaigns } = useStore()
    return { setHero, setCampaigns, fetchHero, fetchCampaigns }
  },
  head() {
    return {
      title: 'Djur&Natur',
      titleTemplate: null,
      meta: [
        {
          property: 'og:title',
          content: 'Djur&Natur',
          vmid: 'og:title'
        },
        {
          property: 'og:url',
          content: import.meta.env.VITE_FRONTEND_URL,
          vmid: 'og:url'
        },
        {
          property: 'og:description',
          content:
            'I din Djur&Natur-butik hittar du säckat foder och tillbehör till hästar, hundar, katter, kaniner och marsvin. Samt till lantbrukets djur som höns, kor, får och gris. Vi har foder och fröblandningar till vilt, trädgårdsprodukter och mycket mer.',
          vmid: 'og:description'
        },
        {
          name: 'description',
          content:
            'I din Djur&Natur-butik hittar du säckat foder och tillbehör till hästar, hundar, katter, kaniner och marsvin. Samt till lantbrukets djur som höns, kor, får och gris. Vi har foder och fröblandningar till vilt, trädgårdsprodukter och mycket mer.'
        }
      ]
    }
  },
  // Server-side only
  serverPrefetch() {
    return Promise.all([this.fetchHero, this.fetchCampaigns])
  }
})
</script>

<template>
  <div>
    <Hero />
    <GridFrontPage />
  </div>
</template>
